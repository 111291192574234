import Banner from "../../sections/common/banner";
import ItodoImage from "../../elements/itodo-img";
import { bannerData } from "../../../globals/banner";
import { NavLink } from "react-router-dom";

function TeamPage() {
  return (
    <>
      <Banner _data={bannerData.team} />

      <div className="section-full p-t110 p-b80 sx-bg-light sx-ourteam-outer ">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title"></div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">Azown Technology Team Members</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="row sx-our-team-section">
              {/*1*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/venkatesh-chavan">
                      <ItodoImage src="images/our-team5/venkatesh.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Founder & Director</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/venkatesh-chavan">
                        Venkatesh Chavan
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*2*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/ajay-patil">
                      <ItodoImage src="images/our-team5/Ajay.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Director</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/ajay-patil">Ajay Patil</NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*3*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/vishal-ghodeswar">
                      <ItodoImage src="images/our-team5/vishal.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Director</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/vishal-ghodeswar">Vishal Ghodeswar</NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*4*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/mahendra-taur">
                      <ItodoImage src="images/our-team5/1.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Director</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/mahendra-taur">Mahendra Taur</NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*5*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/mukta-chavan">
                      <ItodoImage src="images/our-team5/Mukta.png" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Director</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/mukta-chavan">
                      Mukta Chavan
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*6*/}
              {/* <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/khushbu-kesari">
                      <ItodoImage src="images/our-team5/Khushbu.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="/https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Marketing Manager</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/khushbu-kesari">
                        Khushbu Kesari
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/nayan-khobragade">
                      <ItodoImage src="images/our-team5/Nayan.jpeg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="/https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Marketing Manager</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/nayan-khobragade">
                      Nayan Khobragade
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              

              {/*7*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/prashant-chilbile">
                      <ItodoImage src="images/our-team5/Prashant.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Social Media Manager</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/prashant-chilbile">
                        Prashant Chilbile
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*8*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/radheshyam-shiraskar">
                      <ItodoImage
                        src="images/our-team5/Radheshyam.jpg"
                        alt=""
                      />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Social Media Sr. Executive</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/radheshyam-shiraskar">
                        Radheshyam Shiraskar
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*9*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/prachi-pandit">
                      <ItodoImage src="images/our-team5/Prachi.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Web Developer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/prachi-pandit">Prachi Pandit</NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*10*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/shreya-thakur">
                      <ItodoImage src="images/our-team5/Shreya.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Social Media Executive</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/shreya-thakur">Shreya Thakur</NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*11*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/chetan-khote">
                      <ItodoImage src="images/our-team5/Chetan.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Graphic Designer/Video Editor</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/chetan-khote">Chetan Khote</NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*12*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/samarth-narawane">
                      <ItodoImage src="images/our-team5/Samarth.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Video Editor/Cinematographer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/samarth-narawane">
                        Samarth Narawane
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*13*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/nikita-amrutkar">
                      <ItodoImage src="images/our-team5/Nikita.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Graphic Designer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/nikita-amrutkar">
                        Nikita Amrutkar
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*14*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/sharvari-gharat">
                      <ItodoImage src="images/our-team5/sharvari.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Graphic Designer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/sharvari-gharat">
                        Sharvari Gharat
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*15*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/akhilesh-mayande">
                      <ItodoImage src="images/our-team5/Akhilesh.JPG" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Graphic Designer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/akhilesh-mayande">
                        Akhilesh Mayande
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*16*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/karan-dhole">
                      <ItodoImage src="images/our-team5/Karan.jpg " alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Web Developer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/karan-dhole">Karan Dhole</NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*17*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/adinath-garud">
                      <ItodoImage src="images/our-team5/Adinath.jpeg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Web Developer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/devesh">Adinath Garud</NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*18*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/rugved-deshmukh">
                      <ItodoImage src="images/our-team5/Rugved.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Web Developer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/rugved-deshmukh">
                        Rugved Deshmukh
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              {/*19*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/sejal-deshmukh">
                      <ItodoImage src="images/our-team5/Sejal.jpeg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>News Anchor</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/sejal-deshmukh">Sejal Deshmukh</NavLink>
                    </h4>
                  </div>
                </div>
              </div>

              {/*19*/}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/pavan-apotikar">
                      <ItodoImage src="images/our-team5/Pavan.jpeg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Videographer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/pavan-apotikar">Pavan Apotikar</NavLink>
                    </h4>
                  </div>
                </div>
              </div>
              
              {/* 21 */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/shradhha-pawar">
                      <ItodoImage src="images/our-team5/Shradhha.jpeg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Video Editor</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/shradhha-pawar">Shradhha Pawar</NavLink>
                    </h4>
                  </div>
                </div>
              </div> 

              {/* 22 */}
              {/* <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="our-team-2">
                  <div className="profile-image">
                    <NavLink to="/team/devesh-chavan">
                      <ItodoImage src="images/our-team5/Devesh.jpg" alt="" />
                    </NavLink>
                    <div className="icons">
                      <a href="https://www.facebook.com/share/15f8AYE5s3/">
                        <i className="fa fa-facebook" />
                      </a>
                      <a href="https://www.instagram.com/azown_technology/profilecard/?igsh=MWtuZ3Y1OGsxNWFhMg==">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="figcaption">
                    <p>Photographer</p>
                    <h4 className="sx-title">
                      <NavLink to="/team/devesh-chavan">Devesh Chavan</NavLink>
                    </h4>
                  </div>
                </div>
              </div> */} 
            </div>
          </div>
        </div>

        {/*Pagination*/}
        {/* <div className="sx-pagination-wra text-center">
                    <ul className="sx-pagination">
                        <li><a href="/#"><span className="fa fa-long-arrow-left" /></a></li>
                        <li><a href="/#">01</a></li>
                        <li className="active"><a href="/#">02</a></li>
                        <li><a href="/#">03</a></li>
                        <li><a href="/#">04</a></li>
                        <li><a href="/#"><span className="fa fa-long-arrow-right" /></a></li>
                    </ul>
                </div> */}
        {/*Pagination*/}
      </div>
    </>
  );
}

export default TeamPage;








import React from "react";
import TeamCard from "./TeamCard";

const Sharvari = () => {
  const SharvariDetails = {
    name: "Sharvari Gharat",
    designation: "AZE011",
    bloodGroup: "O-", // Blood group not mentioned
    profilePic: "images/our-team5/sharvari.jpg",
    contact: {
      employeeId: "0012", // Employee ID not mentioned
      phone: "+919156281485",
      emergencyPhone: "+91 8087138385",
      email: "sharvari@azown.in",
      companyMail: "sharvari@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Flat no 808  A2 wing Atul Nagar Society Warje 411058",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Sharvari Gharat is a skilled Graphic Designer at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...SharvariDetails} />;
};

export default Sharvari;




import ItodoImage from "../../../elements/itodo-img";
import { publicUrlFor } from "../../../../globals/constants";
//import SectionCounter1 from "./section-counter1";
//import ContactForm from "../../../components/contactus/ContactForm";

function SectionAboutCompany1() {
  return (
    <>
      <div className="section-full  p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx1-content-wrap">
              <div className="row">
                <div
                  className="col-lg-6 col-md-12 wow fadeInDown"
                  data-wow-duration="1000ms"
                >
                  <div className="sx-about-bx1-media">
                    <div className="sx-dot-bx">
                      <ItodoImage src="images/about/dotted-pic.png" alt="" />
                    </div>
                    <div className="sx-ab-img-outer">
                      <div className="sx-ab-img-section-1-wrap">
                        <div className="sx-ab-video">
                          <a href="https://youtube.com/shorts/-5vg1qaIO8o?feature=share" className="mfp-video circle">
                            <i className="flaticon-play" />
                          </a>
                        </div>
                        <div
                          className="sx-ab-img-section-1"
                          style={{
                            backgroundImage: `url(${publicUrlFor(
                              "images/about/Azown-Technology.png"
                            )})`,
                          }}
                        />
                      </div>
                      
                      <div className="sx-ab-img-section-2">
                        <ItodoImage src="images/about/p1.png" alt="" />
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow fadeInDown"
                  data-wow-duration="1000ms"
                >
                  <div className="sx-about-bx1-content">
                    <h1 className="sx-title">
                      Best Digital Marketing Agency In Pune
                    </h1>
                    <span className="sx-title-2">
                      Welcome to Azown Technology
                    </span>
                    <h2 className="sx-title-3">
                      Azown Technology is a leading digital marketing agency in
                      Pune dedicated to helping businesses grow their online
                      presence and achieve measurable success.
                    </h2>
                    <p>
                      Our mission is to deliver customized, resultoriented
                      digital solutions that align with your business goals.
                      With a passionate team of experts specializing in website
                      development, UI/UX design, SEO, SEM, social media
                      marketing, content creation, and paid advertising, we
                      ensure that your brand stands out in the competitive
                      digital landscape.<br/><br/> From conceptualization to execution,
                      Azown Technology provides endtoend digital marketing
                      solutions for businesses of all sizes. We believe in
                      combining creativity with technology to deliver
                      exceptional results.<br/><br/> Whether you need a responsive
                      website, engaging social media campaigns, or search engine
                      visibility, our innovative strategies are designed to
                      deliver ROIdriven growth. Join hands with Azown Technology
                      and experience the power of digital transformation!
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar sx-bg-primary"
                        role="progressbar"
                        style={{ width: "75%" }}
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span>95.00% Work Success</span>
                      </div>
                    </div>
                    <div className="row sx-about-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <p>Focused project objective.</p>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <p>Transperent Communication.</p>
                      </div>
                    </div>
                    <div className="row sx-about-icon-bx1-column">
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-mouse" />
                          </div>
                          <div className="sx-icon-bx-info">
                            <span className="sx-icon-bx-title">
                              Digital Marketing
                            </span>
                            {/* <span className="sx-icon-bx-title-info">02 Project</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="sx-icon-bx-2">
                          <div className="sx-media">
                            <i className="flaticon-download" />
                          </div>
                          <div className="sx-icon-bx-info">
                            <span className="sx-icon-bx-title">
                              IT Solution
                            </span>
                            {/* <span className="sx-icon-bx-title-info">04 Project</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* COUNTER START */}
            
            {/* COUNTER  END */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionAboutCompany1;

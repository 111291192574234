
import ContactForm from "../../../components/contactus/ContactForm";

const pricingPlans = [
    {
        title: "Standard",
        price: "₹20999.00",
        features: [
            { text: "Website Development", enabled: false },
            { text: "UI/UX Designing", enabled: false },
            { text: "Search Engine Optimization (SEO)", enabled: false },
            { text: "Google Ads", enabled: false },
            { text: "Meta Ads", enabled: false },
            { text: "Social Media Marketing", enabled: true },
            { text: "Content Creation", enabled: true },
            { text: "Product Video & Photo Shoot", enabled: true },
            { text: "Performance Marketing", enabled: false },
            { text: "Graphic Design", enabled: true },
        ],
    },
    {
        title: "Ultimate",
        price: "₹24999.00",
        features: [
            { text: "Website Development", enabled: false },
            { text: "UI/UX Designing", enabled: false },
            { text: "Search Engine Optimization (SEO)", enabled: false },
            { text: "Google Ads", enabled: false },
            { text: "Meta Ads", enabled: false },
            { text: "Social Media Marketing", enabled: true },
            { text: "Content Creation", enabled: true },
            { text: "Product Video & Photo Shoot", enabled: true },
            { text: "Performance Marketing", enabled: false },
            { text: "Graphic Design", enabled: true },
            
        ],
        badge: "Most Popular",
    },
    {
        title: "Advanced",
        price: "₹29999.00",
        features: [
            { text: "Website Development", enabled: false },
            { text: "UI/UX Designing", enabled: false },
            { text: "Search Engine Optimization (SEO)", enabled: true },
            { text: "Google Ads", enabled: false },
            { text: "Meta Ads", enabled: false },
            { text: "Social Media Marketing", enabled: true },
            { text: "Content Creation", enabled: true },
            { text: "Product Video & Photo Shoot", enabled: true },
            { text: "Performance Marketing", enabled: false },
            { text: "Graphic Design", enabled: true },
            
        ],
    },
    {
        title: "Professional",
        price: "₹34999.00",
        features: [
            { text: "Website Development", enabled: true },
            { text: "UI/UX Designing", enabled: true },
            { text: "Search Engine Optimization (SEO)", enabled: true },
            { text: "Google Ads", enabled: true },
            { text: "Meta Ads", enabled: true },
            { text: "Social Media Marketing", enabled: true },
            { text: "Content Creation", enabled: true },
            { text: "Product Video & Photo Shoot", enabled: true },
            { text: "Performance Marketing", enabled: true },
            { text: "Graphic Design", enabled: true },
            
        ],
    },
];

export function SectionPricing1Inner() {
    return (
        <div className="container">
            {/* TITLE START */}
            <div className="section-head center">
                <div className="sx-head-s-title">Price Table</div>
                <div className="sx-head-l-title">
                    <h2 className="sx-title">Our Best Pricing Table</h2>
                </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
                <div className="sx-p-table-section">
                    {/* Services List */}
                    <div className="sx-p-table-bx-1 wow fadeInDown" data-wow-duration="1000ms">
                        <div className="sx-p-table-bx-1-top">
                            <h3 className="sx-title">Services / Price</h3>
                        </div>
                        <div className="sx-p-table-bx-1-mid">
                            <ul>
                                <li>Website Development</li>
                                <li>UI/UX Designing</li>
                                <li>Search Engine Optimization (SEO)</li>
                                <li>Search Engine Marketing (SEM) & Paid Advertising</li>
                                <li>Social Media Marketing</li>
                                <li>Content Creation</li>
                                <li>Product Video & Photo Shoot</li>
                                <li>Performance Marketing</li>
                                <li>Graphic Design</li>
                            </ul>
                        </div>
                    </div>

                    {/* Pricing Plans */}
                    {pricingPlans.map((plan, index) => (
                        <div key={index} className={`sx-p-table-bx-2 wow fadeInDown t-r-${index + 2}`} data-wow-duration="1000ms">
                            {plan.badge && (
                                <div className="sx-p-table-badge-wrap">
                                    <div className="sx-p-table-badge">
                                        <span>{plan.badge}</span>
                                    </div>
                                </div>
                            )}
                            <div className="sx-p-table-bx-2-top-title">
                                <ContactForm/>
                            </div>
                            <div className="sx-p-table-bx-2-top">
                            <span>{plan.title}</span>
                            </div>
                            <div className="sx-p-table-bx-2-mid">
                                <ul>
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx}>
                                            <div className={feature.enabled ? "sx-plan-enable" : "sx-plan-disable"}>
                                                <i className={feature.enabled ? "feather-check" : "feather-x"} />
                                            </div>
                                            <span>{feature.enabled ? "True" : "False"}</span> {/* Show True/False */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="sx-p-table-bx-2-top">
                            <h3 className="sx-price">{plan.price}<span>/m</span></h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function SectionPricing1() {
    return (
        <div className="section-full p-t110 p-b110 sx-p-table-outer sx-bg-white wow fadeInDown" data-wow-duration="1000ms">
            <SectionPricing1Inner />
        </div>
    );
}

export default SectionPricing1;
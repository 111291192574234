


import React from "react";
import TeamCard from "./TeamCard";

const Nikita = () => {
  const NikitaDetails = {
    name: "Nikita Amrutkar",
    designation: "Graphic Designer",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Nikita.jpg",
    contact: {
      employeeId: "AZE005", // Employee ID not mentioned
      phone: "+919766610341",
      emergencyPhone: "+91 9860620341",
      email: "nikita@azown.in",
      companyMail: "nikita@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Vishvas appartment, Akra maruti kopra, Shukrawar peth, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Nikita Amrutkar is a skilled Graphic Designer at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...NikitaDetails} />;
};

export default Nikita;





import React from 'react';
import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import './Govermentportfolio.css'; // Import the CSS file for styles

const Govermentportfolio = () => {
  const projects = [
    {
      title: "PMPML",
      description: "Public transport service in Pune.",
      imgSrc: "images/client-logo/white/PMPML.jpg",
    },
    {
      title: "PCMC Divyang Bhavan",
      description: "A facility for the differently-abled.",
      imgSrc: "images/client-logo/white/divyangbhawan.jpg",
    },
    {
      title: "BARTI",
      description: "Research and training institute.",
      imgSrc: "images/client-logo/white/Barti",
    },
    {
      title: "Sasun General Hospital Pune",
      description: "General hospital providing healthcare services.",
      imgSrc: "images/client-logo/white/Sasoon",
    },
    {
      title: "Persons With Disabilities Welfare Government of Maharashtra",
      description: "Welfare programs for persons with disabilities.",
      imgSrc: "images/client-logo/white/GovOfMah",
    },
    {
      title: "Social Justice and Special Assistance",
      description: "Programs for social justice and assistance.",
      imgSrc: "images/client-logo/white/SJSA",
    },
  ];

  return (
    <div className="section-full p-t110 p-b80 sx-portfolio-outer sx-bg-white">
      <div className="container">
        {/* TITLE START */}
        <div className="section-head center">
          <div className="sx-head-s-title">Our Portfolio</div>
          <div className="sx-head-l-title">
            <h2 className="sx-title">Our Projects</h2>
          </div>
        </div>
        {/* TITLE END */}
        <div className="section-content">
          <div className="sx-portfolio-bx-wrap m-b30">
            <div className="portfolio-grid">
              {projects.map((project, index) => (
                <div className="portfolio-item" key={index}>
                  <div className="portfolio-box">
                    <div className="client-img">
                      <NavLink to="">
                        <ItodoImage src={project.imgSrc} alt={project.title} className="project-image" />
                      </NavLink>
                    </div>
                    <h4 className="sx-portfolio-title">{project.title}</h4>
                    {/* <p className="sx-portfolio-description">{project.description}</p> */}
                    {/* <div className="sx-portfolio-btn">
                      <NavLink to="" title="READ MORE" className="site-button icon">
                        <i className="fa fa-long-arrow-right" />
                        View Detail
                      </NavLink>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Govermentportfolio;
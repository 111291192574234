

import React from "react";
import TeamCard from "./TeamCard";

const Prashant = () => {
  const PrashantDetails = {
    name: "Prashant Chilbile",
    designation: "Social Media Manager",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Prashant.jpg",
    contact: {
      employeeId: "AZE009", // Employee ID not mentioned
      phone: "+919405067910",
      emergencyPhone: "+91 9405067910",
      email: "prashant@azown.in",
      companyMail: "prashant@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Vidya Vally Road Susgaon Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Prashant Chilbile.",
  };

  return <TeamCard {...PrashantDetails} />;
};

export default Prashant;









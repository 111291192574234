import { NavLink , useLocation } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { loadScript } from "../../../globals/constants";
import { useEffect } from "react";
//import ContactForm from "../../components/contactus/ContactForm";

function Header1() {

    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        loadScript("js/mobilenav.js")
    })
    const isActiveRoute = (path) => location.pathname === path;

    return (
        <>
            <header className={"header-style1 site-header  mobile-sider-drawer-menu " + (isActive ? "active" : "")}>
                 
                <div className="sticky-header main-bar-wraper navbar-expand-lg flex">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <ItodoImage src="images/Logo.png" alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                           
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav  ">
                                    {/* home */}
                                    <li className={isActiveRoute("/") ? "active" : ""}>
                                        <NavLink to="/">Home</NavLink>
                                        
                                    </li>
                                     {/* Company */}
                                    <li className={isActiveRoute("/about-us") ? "active" : ""}>
                                        <NavLink to="/about-us">Company</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/about-us">About Azown</NavLink></li>
                                          
                                             <li><NavLink to="/team">Team</NavLink></li>
                                             <li><NavLink to="/">Blogs</NavLink></li>
                                        </ul>
                                    </li>
                                     
                                     {/* Government */}
                                    <li className={isActiveRoute("/goverment") ? "active" : ""}>
                                        <NavLink to="/government">Government</NavLink>
                                    </li>
                                     {/* Services */}
                                    <li className={isActiveRoute("/services") ? "active" : ""}>
                                        <NavLink to="/services">Services</NavLink>
                                        <ul className="sub-menu">
                                            
                                            <li><NavLink to="/digital-marketing">Digital Marketing</NavLink></li>
                                            <li><NavLink to="/web-devlopment">Web Devlopment</NavLink></li>
                                            <li><NavLink to="/content-creation">Content Creation</NavLink></li>
                                            <li><NavLink to="/graphic-design">Graphic Design</NavLink></li>
                                            <li><NavLink to="/video-production">Video Production</NavLink></li>                              
                                            <li><NavLink to="/social-media">Edit & Post Production</NavLink></li>
                                     
                                        </ul>
                                    </li>
                                    <li className={isActiveRoute("/portfolio") ? "active" : ""}>
                                        <NavLink to="/portfolio">Portfolio</NavLink>
                                    </li>
                                    
                                    <li className={isActiveRoute("/contact-us") ? "active" : ""}><NavLink to="/contact-us">Contact Us</NavLink></li>
                                    
                                    
                                </ul>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header1;
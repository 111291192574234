

import React from "react";
import TeamCard from "./TeamCard";

const Mukta = () => {
  const MuktaDetails = {
    name: "Mukta Chavhan",
    designation: "Director",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Mukta.png",
    contact: {
      employeeId: "AZM005", // Employee ID not mentioned
      phone: "+919112299435",
      emergencyPhone: "+91 9112299435",
      email: "mukta@azown.in",
      companyMail: "mukta@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Mangal Bhairav, Nanded City, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Mukta Chavhan.",
  };

  return <TeamCard {...MuktaDetails} />;
};

export default Mukta;










import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";

function Webdevlopmentdetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2 flex flex-col items-center">
                                <div className="sx-head-s-title text-center text-2xl font-bold mt-4">
                                <h2>Web & App Development</h2></div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Innovative Solutions for Your Digital Presence</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    In today's digital landscape, having a strong online presence is crucial for success. Our web and app development services are designed to help businesses create engaging, user-friendly platforms that not only attract customers but also enhance their overall experience.
                                </p>
                                <p>
                                    We specialize in building responsive websites and mobile applications tailored to your specific needs. Whether you are looking to create a simple landing page or a complex e-commerce platform, our team of experienced developers and designers are here to bring your vision to life.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-responsive" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Responsive Design</span>
                                                <span className="sx-icon-bx-title-info">Optimized for all devices</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-code" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Custom Development</span>
                                                <span className="sx-icon-bx-title-info">Tailored to your business needs</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-security" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Secure Solutions</span>
                                                <span className="sx-icon-bx-title-info">Protecting your data</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Our team stays updated with the latest technologies and trends in web and app development, ensuring that your project is built using the best practices in the industry. From concept to deployment, we work closely with you to ensure that your project meets your expectations and goals.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Webdevlopmentdetail;
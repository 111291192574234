import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './InquiryForm.css'; // Import the CSS file

function ContactForm() {
    const form = useRef();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_bwcnp0u', 'template_bm3culp', form.current, 'omfVXMBY3m-RW0Oo8')
            .then(
                () => {
                    alert('Message sent successfully!');
                    form.current.reset();
                    setIsPopupOpen(false); // Close the popup after successful submission
                },
                (error) => {
                    console.error('FAILED...', error.text);
                    alert('Failed to send message. Please try again later.');
                }
            );
    };

    return (
        <>
            <button className="site-button" onClick={() => setIsPopupOpen(true)}>
                Enquiry Now
            </button>

            <div className={`popup-overlay ${isPopupOpen ? 'active' : ''}`} onClick={() => setIsPopupOpen(false)}>
                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <button className="close-button" onClick={() => setIsPopupOpen(false)}>
                        &times;
                    </button>
                    <h4 className="sx-title text-center">Get In Touch</h4>
                    <form className="cons-contact-form2 form-transparent" ref={form} onSubmit={sendEmail}>
                        <div className="input input-animate">
                            <input type="text" name="username" id=" name" required placeholder="Your Name" />
                            <span className="spin" />
                        </div>
                        <div className="input input-animate">
                            <input type="email" name="email" id="email" required placeholder="Your Email" />
                            <span className="spin" />
                        </div>
                        <div className="input input-animate">
                            <input type="text" name="phone" id="phone" required placeholder="Phone Number" />
                            <span className="spin" />
                        </div>
                        <div className="input textarea input-animate">
                            <textarea name="message" id="message" required placeholder="Your Message" defaultValue={""} />
                            <span className="spin" />
                        </div>
                        <div className="sx-btn-center text-center p-t10">
                            <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                <i className="fa fa-long-arrow-right" />
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ContactForm;
import React from "react";
import TeamCard from "./TeamCard";

const Ajaypagtil = () => {
  const AjayDetails = {
    name: "Ajay Patil",
    designation: "Director",
    bloodGroup: "O+", // Blood group not mentioned
    profilePic: "images/our-team5/Ajay.jpg",
    contact: {
      employeeId: "AZM002", // Employee ID not mentioned
      phone: "+91 9112299435",
      email: "ajaypatil@gmail.com",
      emergencyPhone: "+91 9112299435",
      companyMail:"ajay@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Azown Technology Pvt. Ltd. Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Ajay Patil is the Director at Azown Technology Pvt. Ltd., leading the team with a vision for innovation and growth.",
  };

  return <TeamCard {...AjayDetails} />;
};

export default Ajaypagtil;

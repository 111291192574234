





import React from "react";
import TeamCard from "./TeamCard";

const Prachi = () => {
  const PrachiDetails = {
    name: "Prachi Pandit",
    designation: "Web Developer",
    bloodGroup: "O+", // Blood group not mentioned
    profilePic: "images/our-team5/Prachi.jpg",
    contact: {
      employeeId: "AZE004", // Employee ID not mentioned
      phone: "+919768606974",
      emergencyPhone: "+91 8123919730",
      email: "prachi@azown.in",
      companyMail: "prachi@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "A 103, Crystal Heights, Wakad, Pune 411057",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Prachi Pandit is a skilled Web Developer at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...PrachiDetails} />;
};

export default Prachi;



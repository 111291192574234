import React from "react";
import Banner from "../../sections/common/banner";
import { Link } from "react-router-dom";
import { bannerData } from "../../../globals/banner";
import service1 from "../../../images/ser-1.jpg";
import service2 from "../../../images/ser-2.jpg";
import service3 from "../../../images/ser-3.jpg";
import service4 from "../../../images/ser-4.jpg";
import service5 from "../../../images/ser-5.jpg";
import service6 from "../../../images/ser-6.jpg";
import service7 from "../../../images/ser-7.jpg";
import service8 from "../../../images/ser-8.jpg";
import service9 from "../../../images/ser-9.jpg";
import service10 from "../../../images/ser-10.jpg";
import service11 from "../../../images/ser-11.jpg";
import service12 from "../../../images/ser-12.jpg";
import "./portfolio.css";
const Portfolio = () => {
  const projects = [
    {
      id: 1,
      title: "PCMC's Purple Jallosh Event- 2025",
      service:"Social Media Management, Digital Marketing",
      image: service1, // Replace with actual image URL
      link: "https://www.instagram.com/purple_jallosh_pcmc/", // Route for this project
    },
    {
      id: 2,
      title: "PCMC's Purple Jallosh Event- 2025",
      service:"Website Developemnt",
      image: service2, // Replace with actual image URL
      link: "https://purplejallosh.pcmcdivyangbhavan.org/", // Route for this project
    },
    {
      id: 3,
      title: "PCMC Divyang Bhavan Foundation-Pune ",
      service:"Website Developemnt , Social Media MAnagement",
      image: service3, // Replace with actual image URL
      link: "https://pcmcdivyangbhavan.org/", // Route for this project
    },
    {
      id: 4,
      title: "PMPML",
      service:"Social Media Management",
      image: service4, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 5,
      title: "Exer Energy",
      service:"Website Developemnt",
      image: service5, // Replace with actual image URL
      link: "https://www.exerenergy.com/", // Route for this project
    },
    {
      id: 6,
      title: "3cit Solutions",
      service:"Website Developemnt",
      image: service6, // Replace with actual image URL
      link: "https://3citsolutions.com/", // Route for this project
    },



    {
      id: 7,
      title: "Nishtha Essentials",
      service:"Website development",
      image: service7, // Replace with actual image URL
      link: "https://www.nishthaessentials.com/", // Route for this project
    },
    {
      id: 8,
      title: "Sarai kids",
      service:"Social Media Management",
      image: service8, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 9,
      title: "Jeevandhara Hospitol",
      service:"Website Developemnt",
      image: service9, // Replace with actual image URL
      link: "https://jeevandhaarahospital.com/", // Route for this project
    },
    {
      id: 10,
      title: "Smit Dental Clinic",
      service:"Digital Marketing",
      image: service10, // Replace with actual image URL
      link: "#", // Route for this project
    },
    {
      id: 11,
      title: "Uncle Ki Taxi",
      service:"Website Developemnt",
      image: service11, // Replace with actual image URL
      link: "https://unclekitaxi.com/", // Route for this project
    },
    {
      id: 12,
      title: " Surprise Cars",
      service:"Digital Marketing",
      image: service12, // Replace with actual image URL
      link: "#", // Route for this project
    },
  ];

  return (
    <div>
      <Banner _data={bannerData.mission} />

      <div className="portfolio-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
                <Link to={project.link} key={project.id} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="title-p">
            <h3 className="project-title" id="title-p1">{project.title}</h3>
            <p className="project-title1">{project.service}</p>
            </div>
          </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;

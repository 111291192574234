import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";
function SectionServices1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div><h2 className="sx-head-s-title"> Empowering Brands with Creative Digital Solutions</h2></div>
                        <div className="sx-head-l-title">
                            <h1 className="sx-title">Services We Are Offering</h1>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                                {/* Block one */}
                                <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="1000ms">
                                <NavLink to="/digital-marketing">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Digital-Marketing.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Digital<br/> Marketing</h4>
                                                
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/digital-marketing" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>
                                {/* Block Two */}
                                <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="1000ms">
                                <NavLink to="/web-devlopment"><div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Software-Developement.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Software<br/> Development</h4>
                                                
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/web-devlopment" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>
                                {/* Block Three */}
                                <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="100ms">
                                <NavLink to="/content-creation">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Content-Creation.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Content<br/> Creation</h4>
                                               
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/content-creation" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>
                                
                                {/* Block Four */}
                                <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="1000ms">
                                <NavLink to="/graphic-design">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Graphic-Designer.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Graphic<br/> Design</h4>
                                                
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/graphic-design" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>
                                 {/* Block Five */}
                                 <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="1000ms">
                                 <NavLink to="/video-production">
                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Video-Production.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Video<br/> Production</h4>
                                                
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/video-production" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>
                                 {/* Block Six */}
                                <div className="col-lg-2 col-md-2 wow fadeInDown" data-wow-duration="1000ms">
                                <NavLink to="/social-media">                                    <div className="sx-service-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-service-bx-icon scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/icons/Social-Media.png" alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Edit & Post<br/> Production</h4>
                                                
                                                {/* <div className="sx-center-btn">
                                                    <NavLink to="/social-media" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </NavLink>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices1;

import React from "react";
import TeamCard from "./TeamCard";

const Nayan = () => {
  const nayanDetails = {
    name: "Nayan Khobragade",
    designation: "Video Editor",
    bloodGroup: "O+",
    profilePic: "images/our-team5/Nayan.jpeg",
    contact: {
      employeeId: "AZE014",
      phone: "+91 721 804 2207",
      email: "nayan@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
      emergencyPhone: "+91 721 804 2207",
      companyMail: "nayan@azown.in" ,
    },
    website: "https://azown.in",
    address: "Susgaon Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description:
      "",
  };

  return <TeamCard {...nayanDetails} />;
};

export default Nayan;







import React from "react";
import TeamCard from "./TeamCard";

const Sejal = () => {
  const SejalDetails = {
    name: "Sejal Deshmukh",
    designation: "News Anchor",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Sejal.jpeg",
    contact: {
      employeeId: "AZE013", // Employee ID not mentioned
      phone: "+91 8830332829",
      emergencyPhone: "+91 9823156151",
      email: "sejal@azown.in",
      companyMail: "sejal@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Vishvas appartment, Akra Maruti kopra, Shukrawar peth, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Sejal Deshmukh is a skilled News Anchor at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...SejalDetails} />;
};

export default Sejal;







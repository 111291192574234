import React, { useState } from "react";
import ItodoImage from "../../elements/itodo-img";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper and SwiperSlide
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import './politicalPage.css'; // Import the external CSS file
import Services from "./Services";
import WorkImages from "./WorkImages";

function SectionTeam2() {
  
  return (
    <>
      
      
     

     
        <Services/>

        
      
    </>
  );
}

export default SectionTeam2;

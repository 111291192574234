import React from "react";
import TeamCard from "./TeamCard";

const Adinath = () => {
  const adinathDetails = {
    name: "Adinath Garud",
    designation: "Web Developer",
    bloodGroup: "O+",
    profilePic: "images/our-team5/Adinath.jpeg",
    contact: {
      employeeId: "AZE002",
      
      email: "adinathgarud123@gmail.com",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
      
      companyMail: "adinath@azown.in" ,
    },
    website: "https://azown.in",
    
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description:
      "A dedicated web developer with a passion for creating dynamic and responsive web applications. Always eager to learn and adapt to new technologies.",
  };

  return <TeamCard {...adinathDetails} />;
};

export default Adinath;

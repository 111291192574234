



import React from "react";
import TeamCard from "./TeamCard";

const Rugved = () => {
  const RugvedDetails = {
    name: "Rugved Deshmukh",
    designation: "Web Developer",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Rugved.jpg",
    contact: {
      employeeId: "AZE007", // Employee ID not mentioned
      phone: "+918788443623",
      emergencyPhone: "+91 8788443623",
      email: "rugved@azown.in",
      companyMail: "rugved@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Karve Nagar, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Rugved Deshmukh is a skilled Web Developer at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...RugvedDetails} />;
};

export default Rugved;



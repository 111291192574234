
import React from "react";
import TeamCard from "./TeamCard";

const ShradhhaPawar = () => {
  const shradhhaDetails = {
    name: "Shradhha Pawar",
    designation: "Video Editor",
    bloodGroup: "B+",
    profilePic: "images/our-team5/Shradhha.jpeg",
    contact: {
      employeeId: "AZE015",
      phone: "+91 8999599908",
      email: "shraddhapawar8010@gmail.com",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
      emergencyPhone: "+91 8999599908",
      companyMail: "shradhha@azown.in" ,
    },
    website: "https://azown.in",
    address: "at post islampur, khambe galli ",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description:
      "Working as a video editor intern",
  };

  return <TeamCard {...shradhhaDetails} />;
};

export default ShradhhaPawar;

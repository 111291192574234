
import SectionTeam2 from "../../sections/political/section-team2";

function Political() {
    return (
        <>
       
        <SectionTeam2/>
        </>
    )
}

export default Political;
import React from "react";
import TeamCard from "./TeamCard";

const Akhilesh = () => {
  const AkhileshDetails = {
    name: "Akhilesh Mayande",
    designation: "Graphic Designer",
    bloodGroup: "", // Blood group not mentioned
    profilePic: "images/our-team5/Akhilesh.jpg",
    contact: {
      employeeId: "AZE010", // Employee ID not mentioned
      phone: "+91 9168308518",
      emergencyPhone: "+91 9168308518",
      email: "akhilesh@azown.in",
      companyMail: "akhilesh@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Azown Technology Pvt. Ltd. Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Akhilesh Mayande is a skilled Graphic Designer at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...AkhileshDetails} />;
};

export default Akhilesh;



import React from "react";
import TeamCard from "./TeamCard";

const PavanApotikar = () => {
  const PavanDetails = {
    name: "Pavan Apotikar",
    designation: "Videographer",
    bloodGroup: "A+",
    profilePic: "images/our-team5/Pavan.jpeg",
    contact: {
      employeeId: "AZE016",
      phone: "+91 9021568402",
      email: "pavan@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
      emergencyPhone: "+91 9021568402",
      companyMail: "pavan@azown.in" ,
    },
    website: "https://azown.in",
    address: "",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description:
      "A dedicated videographer with a passion for capturing compelling visuals and crafting engaging video content. Always eager to explore new techniques and adapt to the latest trends in video production.",
  };

  return <TeamCard {...PavanDetails} />;
};

export default PavanApotikar;

